
import { Inject } from 'inversify-props';
import { Component, Mixins } from 'vue-property-decorator';

import { PRICE_SHOWN } from '@/modules/rates/constants';
import DownloadReportForm, { DownloadReportControlMixin } from '@/modules/common/components/download-report-form.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import Item from '@/modules/common/interfaces/item.interface';
import DiLiteFilterItemsMixin from '../mixins/filter-items.mixin';
import DiLiteMarketService, { DiLiteMarketServiceS } from '../di-lite-market.service';
import { DILiteDownloadExcelForm } from '../di-lite-market-api.service';

@Component({
    components: {
        DownloadReportForm,
    },
    mixins: [
        DiLiteFilterItemsMixin,
    ],
})
export default class DownloadExcelPage extends Mixins(DiLiteFilterItemsMixin, DownloadReportControlMixin) {
    @Inject(DiLiteMarketServiceS)
    private diLiteMarketService!: DiLiteMarketService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    public isLoading = false;

    public get attrs() {
        const { filters, buttons } = this;
        const { isLoading } = this;

        return {
            filters,
            buttons,
            isLoading,
            dataType: 'DI Lite',
        };
    }

    private get buttons() {
        return [
            {
                label: 'Download now',
                onClick: this.downloadExcel.bind(this),
            },
        ];
    }

    private get filters() {
        const { los: mainLos, pos: mainPos } = this.allChannelsService.filters;
        const { numberOfGuests: mainNumberOfGuests } = this.allChannelsService.settings;
        const { mealTypeId: mainMealType } = this.allChannelsService.settings;
        const { roomTypeId: mainRoomType } = this.allChannelsService.settings;
        const { priceShown: mainPriceShown } = this.documentFiltersService;
        const { device: mainDevice } = this.allChannelsService.settings;

        const roomTypeItems = this.roomTypeItems
            .filter(i => i.value !== -1);

        let mealTypeItems = this.mealTypeItems
            .filter(i => i.value !== -1);

        if (!roomTypeItems.length) return [];
        if (!mealTypeItems.length) return [];

        mealTypeItems = mealTypeItems.map(v => ({
            name: v.name,
            value: this.mealTypesService.getMealType(+v.value)!.name,
        }));

        const defaultMealType = mainMealType === -1
            ? [...mealTypeItems]
            : mealTypeItems.filter(i => i.value === this.mealTypesService.getMealType(mainMealType)!.name);

        const defaultRoomType = mainRoomType === -1
            ? [...roomTypeItems]
            : roomTypeItems.filter(i => i.value === mainRoomType);

        const defaultPriceType = this.allChannelsService.settings.priceType || 'lowest';

        const defaulPriceShown = `${(mainPriceShown || PRICE_SHOWN).toLowerCase()}_price`;

        return [
            {
                label: 'Price Type',
                key: 'price_type',
                options: this.priceTypeItems,
                default: defaultPriceType,
            },
            {
                label: 'LOS',
                key: 'los',
                options: this.losItems,
                default: mainLos || this.losItems[0].value,
            },
            {
                label: 'POS',
                key: 'pos',
                options: this.posItems,
                default: mainPos || this.posItems[0].value,
            },
            {
                label: 'Number of guests',
                key: 'occupancy',
                options: this.numberOfGuestsItems,
                default: mainNumberOfGuests || 2,
            },
            {
                label: 'Room type',
                key: 'room_type',
                options: roomTypeItems,
                default: defaultRoomType,
                multiselect: true,
            },
            {
                label: 'Meal type',
                key: 'meal_type',
                options: mealTypeItems,
                default: defaultMealType,
                multiselect: true,
            },
            {
                label: 'Device',
                key: 'device_name',
                options: this.devicesItems,
                default: mainDevice || this.devicesItems[0].value,
            },
            {
                label: 'Price',
                key: 'price',
                options: [
                    { value: 'shown_price', name: PRICE_SHOWN.SHOWN },
                    { value: 'net_price', name: PRICE_SHOWN.NET },
                    { value: 'total_price', name: PRICE_SHOWN.TOTAL },
                ],
                default: defaulPriceShown,
            },
        ];
    }

    private async downloadExcel(form: DILiteDownloadExcelForm) {
        const mealType = (form.meal_type as unknown as Item[])
            .map(i => i.value) as string[];

        const roomType = (form.room_type as unknown as Item[])
            .map(i => i.name) as string[];

        try {
            this.isLoading = true;
            const { month, year } = this.documentFiltersService;
            const lastDate = new Date(year, month + 1, 0);
            const formattedMonth = month >= 9 ? String(month + 1) : `0${month + 1}`;
            const hotelId = +this.$route.params.hotelId;
            await this.diLiteMarketService.downloadExcel({
                ...form,
                room_type: roomType,
                meal_type: mealType,
                device_name: form.device_name || undefined,
                start_date: `${year}-${formattedMonth}-01`,
                end_date: `${year}-${formattedMonth}-${lastDate.getDate()}`,
            }, hotelId);
            this.closeForm();
        } catch (_) {
            this.triggerFormError('There is no data. Try change the filters.');
        } finally {
            this.isLoading = false;
        }
    }
}
